import PropTypes from 'prop-types';
import React from 'react';

import styles from './CompanyLogos.module.css';
import logoList from './logos';

const CompanyLogos = ({ logos, isGrid, noOfColumns = 4 }) => {
  return (
    <ul
      className={styles.CompanyLogos}
      style={{
        '--is-grid': isGrid ? 'grid' : 'flex',
        '--no-of-columns': noOfColumns,
      }}
    >
      {logos.map((logo) => {
        const { alt, src, width } = logoList[logo];
        return (
          <li
            className={styles['CompanyLogos-item']}
            key={logo}
            style={{
              '--align-self-default': isGrid ? 'center' : 'flex-end',
              '--align-self-odd': isGrid ? 'center' : 'flex-start',
            }}
          >
            <img
              alt={alt}
              className={styles['CompanyLogos-image']}
              loading="lazy"
              src={src}
              width={width}
            />
          </li>
        );
      })}
    </ul>
  );
};

CompanyLogos.propTypes = {
  /**
   * Should the logos always be displayed in uniform grid columms?
   */
  isGrid: PropTypes.bool,

  /**
   * Array of logo names to show. For best results, provide 4 logos.
   */
  logos: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(logoList))).isRequired,

  /**
   * If the logos are displayed in a grid (isGrid), how many columns should that grid have?
   */
  noOfColumns: PropTypes.number,
};

export default CompanyLogos;
